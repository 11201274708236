const locale = {
  LOCALE: 'pl',

  LANG_PL: 'PL',
  LANG_EN: 'ENG',
  LANG_DE: 'DE',

  NAVBAR_MENU_SPECIALIZATION: 'SPECJALIZACJA',
  NAVBAR_MENU_TEAM: 'ZESPÓŁ',
  NAVBAR_MENU_RESPNSIBILITY: 'CSR',
  NAVBAR_MENU_CONTACTS: 'KONTAKT',
  NAVBAR_MENU_PART_OF: 'Jesteśmy członkiem',

  BUTTON_MORE: 'WIĘCEJ',
  BUTTON_HIDE: 'Ukryj',

  BUTTON_CHECK: 'SPRAWDŹ',

  BUTTON_MAP_PART_ONE: 'ZNAJDŹ NAS',
  BUTTON_MAP_PART_TWO: 'W GOOGLE MAPS',
}

export default locale
