import React from 'react'
import { IntlProvider } from 'react-intl'

import Layout from './layout'

import messages from '../../locale/pl'
// import pl from 'react-intl/locale-data/pl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/pl'
import 'intl/locale-data/jsonp/pl'
import 'intl-pluralrules'

// addLocaleData(pl)

const LayoutPL = ({ children }) => (
  <IntlProvider locale="pl" messages={messages}>
    <Layout>{children}</Layout>
  </IntlProvider>
)

export default LayoutPL
